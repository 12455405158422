import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, unref as _unref, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "food-detail__actions" }
const _hoisted_2 = { class: "food-detail__cancel-p" }
const _hoisted_3 = { class: "food__enabled" }
const _hoisted_4 = { for: "switchEnabled" }
const _hoisted_5 = { class: "l-container" }
const _hoisted_6 = ["aria-label"]
const _hoisted_7 = { class: "p-grid w-100" }
const _hoisted_8 = { class: "p-col-9" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "p-grid" }
const _hoisted_11 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_12 = { for: "name" }
const _hoisted_13 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_14 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_15 = { for: "category" }
const _hoisted_16 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_17 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_18 = { for: "mealType" }
const _hoisted_19 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_20 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_21 = { for: "units" }
const _hoisted_22 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_23 = {
  key: 0,
  class: "p-field p-col-6 p-fluid"
}
const _hoisted_24 = { for: "unitWeight" }
const _hoisted_25 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_26 = {
  key: 1,
  class: "p-field p-col-6 p-fluid"
}
const _hoisted_27 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_28 = { for: "foodIntolerances" }
const _hoisted_29 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_30 = { for: "foodExclusions" }
const _hoisted_31 = { class: "section-header" }
const _hoisted_32 = { class: "p-grid" }
const _hoisted_33 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_34 = { for: "calories" }
const _hoisted_35 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_36 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_37 = { for: "protein" }
const _hoisted_38 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_39 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_40 = { for: "fat" }
const _hoisted_41 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_42 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_43 = { for: "carbohydrates" }
const _hoisted_44 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_45 = { class: "p-col-3" }
const _hoisted_46 = { class: "bites-container" }
const _hoisted_47 = { class: "p-field p-fluid macro-detail" }
const _hoisted_48 = { class: "macro-detail-title" }
const _hoisted_49 = { class: "macro-detail-description" }
const _hoisted_50 = { class: "macro-detail-quantity" }
const _hoisted_51 = { class: "p-field p-fluid macro-detail" }
const _hoisted_52 = { class: "macro-detail-title" }
const _hoisted_53 = { class: "macro-detail-description" }
const _hoisted_54 = { class: "macro-detail-quantity" }
const _hoisted_55 = { class: "p-field p-fluid macro-detail" }
const _hoisted_56 = { class: "macro-detail-title" }
const _hoisted_57 = { class: "macro-detail-description" }
const _hoisted_58 = { class: "macro-detail-quantity" }
const _hoisted_59 = { class: "p-field p-fluid macro-detail" }
const _hoisted_60 = { class: "macro-detail-title" }
const _hoisted_61 = { class: "macro-detail-description" }
const _hoisted_62 = { class: "macro-detail-quantity" }
const _hoisted_63 = { key: 0 }
const _hoisted_64 = { class: "food-deactivate-name" }
const _hoisted_65 = { key: 1 }
const _hoisted_66 = { class: "food-deactivate-name" }

import HeadingComponent from '@/components/HeadingComponent.vue';
import {
  createFoodForm,
  Food,
  FoodCategory,
  FoodDto,
  FoodUnit,
  isFormCreateValid,
  MealType,
} from '@/models/NutritionalPlan';

import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { ServiceError } from '@/services/util/ServiceError';

import { useToast } from 'primevue/usetoast';
import { computed, onMounted, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import CardComponent from '@/components/CardComponent.vue';
import HealzInput from '@/components/healz/ui/HealzInput.vue';
import inputHelper from '@/helpers/Input.helper';
import { Intolerances } from '@/models/PatientInfo';
import { MenuItem } from 'primevue/menuitem';


export default /*@__PURE__*/_defineComponent({
  __name: 'FoodDetailPage',
  setup(__props) {

const { t } = useI18n();
const toast = useToast();
const route = useRoute();
const foodId = Array.isArray(route.params.foodId) ? route.params.foodId[0] : route.params.foodId;
const breadcrumb = ref([] as Array<MenuItem>);

const food = ref<Food>({} as Food);
const foodDto: Ref<FoodDto> = ref({} as FoodDto);

const canBeEdited = computed(() => {
  if (food.value && food.value.id) {
    return !food.value.healzValue;
  }
  return false;
});

const showValidation = ref(false);
const editMode = ref(false);
const validatedForm = computed(() => createFoodForm(foodDto.value));
const valid = computed(() => isFormCreateValid(validatedForm.value));
const submitting = ref(false);

const showDeleteDialog = ref(false);
const loadingDelete = ref(false);

const showDeactivateDialog = ref(false);

onMounted(async () => {
  await loadFood();
});

const loadFood = async () => {
  const result = await nutritionalPlanService.findFoodById(foodId);
  if (!(result instanceof ServiceError)) {
    food.value = result;
    foodDto.value = inputHelper.createCopy<FoodDto>(new FoodDto(food.value));

    breadcrumb.value = [
      { label: t('breadcrumb.foods'), to: `/foods` },
      {
        label: result.name,
        to: '#',
        disabled: true,
      },
    ];
  }
};

const categoryOptions = computed(() => {
  return Object.values(FoodCategory)
    .map((category) => ({
      name: t(`food.categories.${category}`),
      value: category,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
});

const mealTypeOptions = computed(() => {
  return Object.values(MealType)
    .map((type) => ({
      name: t(`food.mealTypes.${type}`),
      value: type,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
});

const foodUnitOptions = computed(() => {
  return Object.values(FoodUnit)
    .map((foodUnit) => ({
      name: t(`food.unit.${foodUnit}`),
      value: foodUnit,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
});

const foodIntolerancesOptions = computed(() => {
  return Object.values(Intolerances)
    .map((foodIntolerance) => ({
      name: t(`food.foodIntolerance.${foodIntolerance}`),
      value: foodIntolerance,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
});

const onDeactivatedChange = async () => {
  showDeactivateDialog.value = true;
};

const onCancelDeactivated = () => {
  showDeactivateDialog.value = false;
  foodDto.value.deactivated = !foodDto.value.deactivated;
};

const onDeactivated = async () => {
  loadingDelete.value = true;
  const result = await nutritionalPlanService.updateDeactivated(food.value.id, foodDto.value.deactivated);

  if (result instanceof ServiceError) {
    toast.add({
      severity: 'error',
      summary: `${t('messages.notifications.errorUpdateFood')} ${t('messages.notifications.tryLater')}`,
      life: 3000,
    });
  } else {
    toast.add({ severity: 'success', summary: `${t('messages.notifications.successUpdateFood')}`, life: 3000 });
    showDeactivateDialog.value = false;
  }

  loadingDelete.value = false;
};

const onDeleteClick = async () => {
  showDeleteDialog.value = true;
};

const onDelete = async () => {
  if (food.value) {
    loadingDelete.value = true;
    const result = await nutritionalPlanService.deleteFood(food.value.id);

    if (result instanceof ServiceError) {
      toast.add({
        severity: 'error',
        summary: `${t('messages.notifications.errorDeleteFood')} ${t('messages.notifications.tryLater')}`,
        life: 3000,
      });
    } else {
      toast.add({ severity: 'success', summary: `${t('messages.notifications.successDeleteFood')}`, life: 3000 });
      await loadFood();
    }

    loadingDelete.value = false;
  }
};

const onCancelEdition = async () => {
  editMode.value = false;
  foodDto.value = inputHelper.createCopy<FoodDto>(new FoodDto(food.value));
};

async function saveFood() {
  submitting.value = true;
  showValidation.value = true;
  if (valid.value) {
    const result = await nutritionalPlanService.updateFood(food.value.id, foodDto.value);

    if (result instanceof ServiceError) {
      toast.add({
        severity: 'error',
        summary: `${t('messages.notifications.errorEditFood')} ${t('messages.notifications.tryLater')}`,
        life: 3000,
      });
    } else {
      editMode.value = false;
      showValidation.value = false;

      food.value = result;
      foodDto.value = inputHelper.createCopy<FoodDto>(new FoodDto(food.value));

      toast.add({ severity: 'success', summary: `${t('messages.notifications.successUpdateFood')}`, life: 3000 });
    }
  }
  submitting.value = false;
}

return (_ctx: any,_cache: any) => {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (food.value)
      ? (_openBlock(), _createBlock(HeadingComponent, {
          key: 0,
          title: food.value?.name,
          "heading-icon": food.value.healzValue ? 'icon-healz' : 'icon-custom',
          breadcrumb: breadcrumb.value
        }, {
          right: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (canBeEdited.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "food-detail__cancel",
                    onClick: onDeleteClick
                  }, [
                    _cache[16] || (_cache[16] = _createElementVNode("i", {
                      class: "icon-trash_full food-detail__cancel-icon",
                      "aria-hidden": "true"
                    }, null, -1)),
                    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('common.delete')), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_InputSwitch, {
                  modelValue: foodDto.value.deactivated,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((foodDto.value.deactivated) = $event)),
                  inputId: "switchEnabled",
                  "true-value": false,
                  "false-value": true,
                  onChange: onDeactivatedChange
                }, null, 8, ["modelValue"]),
                _createElementVNode("label", _hoisted_4, _toDisplayString(foodDto.value.deactivated ? _ctx.$t('nutrition.deactivated-food') : _ctx.$t('nutrition.activated-food')), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["title", "heading-icon", "breadcrumb"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(CardComponent, {
        cardTitle: _ctx.$t('food.title'),
        "edit-mode": editMode.value
      }, {
        headingActions: _withCtx(() => [
          (canBeEdited.value)
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 0,
                class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", editMode.value ? 'p-disabled' : '']),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (editMode.value = !editMode.value))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: "icon-edit",
                    "aria-label": _ctx.$t('common.edit'),
                    "aria-hidden": "true"
                  }, null, 8, _hoisted_6)
                ]),
                _: 1
              }, 8, ["class"])), [
                [
                  _directive_tooltip,
                  _ctx.$t('common.edit'),
                  void 0,
                  { bottom: true }
                ]
              ])
            : _createCommentVNode("", true)
        ]),
        cardContent: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (food.value)
                ? (_openBlock(), _createElementBlock("form", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('person.name')) + " " + _toDisplayString(editMode.value ? '*' : ''), 1),
                        _createVNode(HealzInput, {
                          id: "name",
                          modelValue: foodDto.value.name,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((foodDto.value.name) = $event)),
                          modelModifiers: { trim: true },
                          editable: editMode.value,
                          class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedForm.value.name.valid })
                        }, null, 8, ["modelValue", "editable", "class"]),
                        (showValidation.value && !validatedForm.value.name.valid)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_13, _toDisplayString(_ctx.$t(`${validatedForm.value.name.message}`)), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('food.category')) + "*", 1),
                        _createVNode(_component_Dropdown, {
                          id: "category",
                          modelValue: foodDto.value.category,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((foodDto.value.category) = $event)),
                          options: categoryOptions.value,
                          "option-label": "name",
                          "option-value": "value",
                          class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedForm.value.category.valid, 'no-editable': !editMode.value })
                        }, null, 8, ["modelValue", "options", "class"]),
                        (showValidation.value && !validatedForm.value.category.valid)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_16, _toDisplayString(_ctx.$t(`${validatedForm.value.category.message}`)), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('food.mealType')) + "*", 1),
                        _createVNode(_component_MultiSelect, {
                          id: "mealType",
                          modelValue: foodDto.value.mealTypes,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((foodDto.value.mealTypes) = $event)),
                          options: mealTypeOptions.value,
                          "option-label": "name",
                          "option-value": "value",
                          class: _normalizeClass([
                      'multiselect-custom',
                      { 'p-invalid': showValidation.value && !validatedForm.value.mealTypes.valid, 'no-editable': !editMode.value },
                    ])
                        }, null, 8, ["modelValue", "options", "class"]),
                        (showValidation.value && !validatedForm.value.mealTypes.valid)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_19, _toDisplayString(_ctx.$t(`${validatedForm.value.mealTypes.message}`)), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('food.units')) + "*", 1),
                        _createVNode(_component_Dropdown, {
                          id: "units",
                          modelValue: foodDto.value.units,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((foodDto.value.units) = $event)),
                          options: foodUnitOptions.value,
                          "option-label": "name",
                          "option-value": "value",
                          class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedForm.value.units.valid, 'no-editable': !editMode.value })
                        }, null, 8, ["modelValue", "options", "class"]),
                        (showValidation.value && !validatedForm.value.units.valid)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_22, _toDisplayString(_ctx.$t(`${validatedForm.value.units.message}`)), 1))
                          : _createCommentVNode("", true)
                      ]),
                      (foodDto.value.units === _unref(FoodUnit).u)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                            _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t('food.unitWeight')) + "*", 1),
                            _createVNode(_component_InputNumber, {
                              id: "unitWeight",
                              modelValue: foodDto.value.unitWeight,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((foodDto.value.unitWeight) = $event)),
                              required: "true",
                              class: _normalizeClass({
                      'p-invalid': showValidation.value && !validatedForm.value.unitWeight.valid,
                      'no-editable': !editMode.value,
                    })
                            }, null, 8, ["modelValue", "class"]),
                            (showValidation.value && !validatedForm.value.unitWeight.valid)
                              ? (_openBlock(), _createElementBlock("small", _hoisted_25, _toDisplayString(_ctx.$t(`${validatedForm.value.unitWeight.message}`)), 1))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (foodDto.value.units === _unref(FoodUnit).u)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_26))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t('food.foodIntolerances')) + "*", 1),
                        _createVNode(_component_MultiSelect, {
                          id: "foodIntolerances",
                          modelValue: foodDto.value.foodIntolerances,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((foodDto.value.foodIntolerances) = $event)),
                          options: foodIntolerancesOptions.value,
                          "option-label": "name",
                          "option-value": "value",
                          class: _normalizeClass([
                      'multiselect-custom',
                      {
                        'no-editable': !editMode.value,
                      },
                    ])
                        }, null, 8, ["modelValue", "options", "class"])
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$t('food.foodExclusions')) + "*", 1),
                        _createVNode(_component_MultiSelect, {
                          id: "foodExclusions",
                          modelValue: foodDto.value.foodExclusions,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((foodDto.value.foodExclusions) = $event)),
                          options: foodIntolerancesOptions.value,
                          "option-label": "name",
                          "option-value": "value",
                          class: _normalizeClass(['multiselect-custom', { 'no-editable': !editMode.value }])
                        }, null, 8, ["modelValue", "options", "class"])
                      ])
                    ]),
                    _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.$t('food.values-100g')), 1),
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("label", _hoisted_34, _toDisplayString(_ctx.$t('food.caloriesPer100')) + "*", 1),
                        _createVNode(_component_InputNumber, {
                          id: "calories",
                          modelValue: foodDto.value.calories,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((foodDto.value.calories) = $event)),
                          required: "true",
                          class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedForm.value.calories.valid, 'no-editable': !editMode.value })
                        }, null, 8, ["modelValue", "class"]),
                        (showValidation.value && !validatedForm.value.calories.valid)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_35, _toDisplayString(_ctx.$t(`${validatedForm.value.calories.message}`)), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_36, [
                        _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.$t('food.proteinPer100')) + "*", 1),
                        _createVNode(_component_InputNumber, {
                          id: "protein",
                          modelValue: foodDto.value.protein,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((foodDto.value.protein) = $event)),
                          required: "true",
                          class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedForm.value.protein.valid, 'no-editable': !editMode.value })
                        }, null, 8, ["modelValue", "class"]),
                        (showValidation.value && !validatedForm.value.protein.valid)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_38, _toDisplayString(_ctx.$t(`${validatedForm.value.protein.message}`)), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_39, [
                        _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t('food.fatPer100')) + "*", 1),
                        _createVNode(_component_InputNumber, {
                          id: "fat",
                          modelValue: foodDto.value.fat,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((foodDto.value.fat) = $event)),
                          required: "true",
                          class: _normalizeClass({ 'p-invalid': showValidation.value && !validatedForm.value.fat.valid, 'no-editable': !editMode.value })
                        }, null, 8, ["modelValue", "class"]),
                        (showValidation.value && !validatedForm.value.fat.valid)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_41, _toDisplayString(_ctx.$t(`${validatedForm.value.fat.message}`)), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_42, [
                        _createElementVNode("label", _hoisted_43, _toDisplayString(_ctx.$t('food.carbohydratesPer100')) + "*", 1),
                        _createVNode(_component_InputNumber, {
                          id: "carbohydrates",
                          modelValue: foodDto.value.carbohydrates,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((foodDto.value.carbohydrates) = $event)),
                          required: "true",
                          class: _normalizeClass({
                      'p-invalid': showValidation.value && !validatedForm.value.carbohydrates.valid,
                      'no-editable': !editMode.value,
                    })
                        }, null, 8, ["modelValue", "class"]),
                        (showValidation.value && !validatedForm.value.carbohydrates.valid)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_44, _toDisplayString(_ctx.$t(`${validatedForm.value.carbohydrates.message}`)), 1))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_45, [
              _createElementVNode("div", _hoisted_46, [
                _createElementVNode("div", _hoisted_47, [
                  _createElementVNode("div", _hoisted_48, [
                    _cache[17] || (_cache[17] = _createElementVNode("i", { class: "icon-empty-plate" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t(`nutrition.portion`)), 1)
                  ]),
                  _createElementVNode("p", _hoisted_49, [
                    _createTextVNode(_toDisplayString(_ctx.$t('nutrition.totalPortion') + ': ') + " ", 1),
                    _createElementVNode("span", _hoisted_50, _toDisplayString(food.value.quantity), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_51, [
                  _createElementVNode("div", _hoisted_52, [
                    _cache[18] || (_cache[18] = _createElementVNode("i", { class: "icon-proteins" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t(`nutrition.protein`)), 1)
                  ]),
                  _createElementVNode("p", _hoisted_53, [
                    _createTextVNode(_toDisplayString(_ctx.$t('nutrition.totalHealzyBites') + ': ') + " ", 1),
                    _createElementVNode("span", _hoisted_54, _toDisplayString(food.value.proteinBites), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_55, [
                  _createElementVNode("div", _hoisted_56, [
                    _cache[19] || (_cache[19] = _createElementVNode("i", { class: "icon-fats" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t(`nutrition.fat`)), 1)
                  ]),
                  _createElementVNode("p", _hoisted_57, [
                    _createTextVNode(_toDisplayString(_ctx.$t('nutrition.totalHealzyBites') + ': ') + " ", 1),
                    _createElementVNode("span", _hoisted_58, _toDisplayString(food.value.fatBites), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_59, [
                  _createElementVNode("div", _hoisted_60, [
                    _cache[20] || (_cache[20] = _createElementVNode("i", { class: "icon-hc" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t(`nutrition.hc`)), 1)
                  ]),
                  _createElementVNode("p", _hoisted_61, [
                    _createTextVNode(_toDisplayString(_ctx.$t('nutrition.totalHealzyBites') + ': ') + " ", 1),
                    _createElementVNode("span", _hoisted_62, _toDisplayString(food.value.carbohydratesBites), 1)
                  ])
                ])
              ])
            ])
          ])
        ]),
        footerActions: _withCtx(() => [
          _createVNode(_component_Button, {
            class: "p-button-secondary p-button-medium",
            label: _ctx.$t('common.cancel'),
            onClick: onCancelEdition
          }, null, 8, ["label"]),
          _createVNode(_component_Button, {
            class: "p-button p-button-info p-button-medium",
            loading: submitting.value,
            label: _ctx.$t('common.save'),
            disabled: showValidation.value && !valid.value,
            onClick: saveFood
          }, null, 8, ["loading", "label", "disabled"])
        ]),
        _: 1
      }, 8, ["cardTitle", "edit-mode"])
    ]),
    _createVNode(_component_Dialog, {
      visible: showDeleteDialog.value,
      "onUpdate:visible": _cache[14] || (_cache[14] = ($event: any) => ((showDeleteDialog).value = $event)),
      header: _ctx.$t('food.deleteFoodModal.title'),
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('common.cancel'),
          class: "p-button p-component p-button-secondary p-button-medium",
          onClick: _cache[13] || (_cache[13] = ($event: any) => (showDeleteDialog.value = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('common.delete'),
          class: "p-button p-component p-button-secondary p-button-medium delete-food-confirm-button",
          loading: loadingDelete.value,
          onClick: onDelete
        }, null, 8, ["label", "loading"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('food.deleteFoodModal.description')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('messages.actionNotUndone')), 1)
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_component_Dialog, {
      visible: showDeactivateDialog.value,
      "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => ((showDeactivateDialog).value = $event)),
      header: 
      foodDto.value.deactivated
        ? _ctx.$t('food.deactivateFoodModal.deactivateTitle')
        : _ctx.$t('food.deactivateFoodModal.activateTitle')
    ,
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('common.cancel'),
          class: "p-button p-component p-button-secondary p-button-medium",
          onClick: onCancelDeactivated
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: foodDto.value.deactivated ? _ctx.$t('common.deactivate') : _ctx.$t('common.activate'),
          class: _normalizeClass(["p-button p-component p-button-medium", {
          'p-button-primary': !foodDto.value.deactivated,
          'p-button-secondary': foodDto.value.deactivated,
          'delete-food-confirm-button': foodDto.value.deactivated,
        }]),
          loading: submitting.value,
          onClick: onDeactivated
        }, null, 8, ["label", "class", "loading"])
      ]),
      default: _withCtx(() => [
        (foodDto.value.deactivated)
          ? (_openBlock(), _createElementBlock("p", _hoisted_63, [
              _createTextVNode(_toDisplayString(_ctx.$t('food.deactivateFoodModal.deactivateDescription1')) + " ", 1),
              _createElementVNode("span", _hoisted_64, _toDisplayString(foodDto.value.name), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('food.deactivateFoodModal.deactivateDescription2')), 1)
            ]))
          : _createCommentVNode("", true),
        (!foodDto.value.deactivated)
          ? (_openBlock(), _createElementBlock("p", _hoisted_65, [
              _createTextVNode(_toDisplayString(_ctx.$t('food.deactivateFoodModal.activateDescription1')) + " ", 1),
              _createElementVNode("span", _hoisted_66, _toDisplayString(foodDto.value.name), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('food.deactivateFoodModal.activateDescription2')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}
}

})